import React,{useState} from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './D6_halo_pbizzplt.css'
import Header from '../common/Header/D6_SL_Header'
import Footer from '../common/Footer/D6_SL_Footer'
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import {useCookies} from 'react-cookie'
import Dialog from '@material-ui/core/Dialog';
import D6_FE_AccountBox from '../SignUPForm/D6_FE_AccountBox';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

export default function Pricingpbizzplt(props) {
    const{filter,live}=props
    const[auth,setAuth]=useCookies(['auth'])
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
      };
      const [open1, setOpen1] = React.useState(false);
      const handleClose1 = () => {
          setOpen1(false);
        };
const UpdatePlan=(value)=>{
    if(auth.auth){
       
            props.updatePlan(value)
      
    }
    else{
        setOpen(true)
    }
}
    return (
        <>
            {/* <div className="Dec6_Pricing_BgImage_Block"></div> */}
            <div className="D6_PricingCont_GridPbizzPlt">
                {/* <Grid container > */}
                <Grid container item sm={12} xs={12} className="D6_BussCont_GridPbizzPlt">
                        <Grid  item sm={4} xs={12}>
                        <Dialog
      disableScrollLock={ true }
      open={open}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
<D6_FE_AccountBox  live={live}handleClose={handleClose} />     
    </Dialog>  <Dialog
    disableScrollLock={ true }
    open={open1}
      onClose={handleClose1}
      fullWidth
      maxWidth="sm"
      
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
<Share live={live} handleClose1={handleClose1}/>    </Dialog>
                            <Paper className="D6_Buss_GridPbizz1">
                            <div className="D6_PricingBuss_HeadPbizzPlt">Starter ({filter[0]?filter[3].currencySymbol+filter[3].amount:null}*)</div> 
                                <ul>
                                {/* <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Includes everything in classic plan, plus:
                                </li> */}
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                Upto {filter[0]?filter[3].cards:null} cards
                                </li>
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Fully customizable templates
                                </li>
                               
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Additional business details
                                </li>
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Include business hours, pay options
                                </li>
                               
                                <div className="D6_PricingBuss_TextDescPbizzPlt">
                                    &nbsp;
                                </div>
                                <div className="D6_PricingBuss_TextHPbizzPlt">
                                *Billed annually ({filter[0]?filter[3].currencySymbol+filter[3].perCard:null}/card/month)
                                </div>
                                </ul>
                                <div className="D6_PricingBuss_SelectPbizzPlt">
                                <Button onClick={()=>UpdatePlan('starter')} className="D6_PricingButtonBussPbizzPlt" variant="contained">Select</Button>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid  item sm={4} xs={12}>
                            <Paper className="D6_Buss_GridPbizz2">
                            <div className="D6_PricingBuss_HeadPbizzPlt">Advanced ({filter[0]?filter[4].currencySymbol+filter[4].amount:null}*)</div> 
                            <ul>
                                {/* <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Includes everything in classic plan, plus:
                                </li> */}
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                Upto {filter[0]?filter[4].cards:null} cards
                                </li>
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Fully customizable templates
                                </li>
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Additional business details
                                </li>
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Include business hours, pay options
                                </li>
                                <div className="D6_PricingBuss_TextDescPbizzPlt">
                                    &nbsp;
                                </div>
                          
                                <div className="D6_PricingBuss_TextHPbizzPlt">
                                *Billed annually ({filter[0]?filter[4].currencySymbol+filter[4].perCard:null}/card/month)
                                </div>
                                </ul>
                                <div className="D6_PricingBuss_SelectPbizzPlt">
                                <Button  onClick={()=>UpdatePlan('advance')} className="D6_PricingButtonBussPbizzPlt" variant="contained">Select</Button>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid  item sm={4} xs={12}>
                            <Paper className="D6_Buss_GridPbizz3">
                            <div className="D6_PricingBuss_HeadPbizzPlt">Infinity</div> 
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Setup your teams
                                </li>
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Organisation view &amp; control
                                </li>
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Add/remove employees &amp; their permissions
                                </li>
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Special customised pricing plans
                                </li>
                                <li className="D6_PricingBuss_TextDescPbizzPlt">
                                    Bespoke templates for your business/brand
                                </li>
                                <div className="D6_PricingBuss_TextDescPbizzPlt">
                                     &nbsp;
                                </div>
                                <div className="D6_PricingBuss_TextDescPbizzPlt">
                                     &nbsp;
                                </div>
                                <div className="D6_PricingBuss_SelectPbizzPlt">
                                <Button onClick={()=>setOpen1(true)}  className="D6_PricingButtonBussPbizzPlt" variant="contained">Email</Button>
                                </div>
                            </Paper>
                        </Grid>
                        {/* <Grid  item sm={12} xs={12}>
                                <Paper className="D6_Buss_GridPbizz">
                                    Create your digital card
                                </Paper>
                        </Grid> */}
                    </Grid>
                  
                {/* </Grid> */}
            </div>

        </>
    )
}
function Share(props){
    const{live}=props
    const [email,setEmail]=useState('')
    const [contact,setContact]=useState('')
    const [requirements,setRequirements]=useState('')


    const [send,setSending]=useState(false)
  
  const handleSubmit=()=>{
    setSending(true)
    const formData=new FormData()
    formData.append('email',email)
    formData.append('req',requirements)
    formData.append('contact',contact)
    formData.append('category','Signature')
  
  
    {
  
  
      fetch(live+'share'
      ,
        {
    
          method:'PUT',
     
          body:formData
          })
          .then(res=> res.json())
          .then((jsonRes)=>SendRes(jsonRes))
  
          
        
          .catch(error=>console.log(error))
        
        }
        const SendRes=(resp)=>{
          if(resp.detail==='Mail Sent')
          {
            props.handleClose1()
          }
        }
  
  }
  return(
    <div >
              <Grid container spacing={0} xs={12} >
              <ValidatorForm 
                //  useRef="form"
                style={{width:'330px'}}
                  onSubmit={handleSubmit}
                  className='validator-form'
                 
              >
                <Grid  style={{margin:'20px'}}item xs={12}>
            
                <TextValidator
                        label="Your Email"
  
                        onChange={(e)=>setEmail(e.target.value)}
                        className="D6_SIForm_errorf"
                        name=""
                        type='email'
  
                        value={email}     
                        validators={['required','isEmail']}
                        errorMessages={['Email is required']}
                    />
                     <TextValidator
                        label="Your Contact No."
  
                        onChange={(e)=>setContact(e.target.value)}
                        className="D6_SIForm_errorf"
                        name=""
                        type='number'
  
                        value={contact}     
                        validators={['required']}
                        errorMessages={['Contact number is required']}
                    />
                     <TextValidator
                        label=" Your Message"
  
                        onChange={(e)=>setRequirements(e.target.value)}
                        className="D6_SIForm_errorf"
                        name=""
                        type='text'
  
                        value={requirements}     
                        validators={['required']}
                        errorMessages={['Please provide description']}
                    />
  
           
  
                  </Grid>
                  <Grid  style={{margin:'20px'}}item xs={12}>
                    <Button type='submit' className="D6_Info_Button">
                    { !send?' Send':'sending'}
                    </Button>
                    </Grid>
                    </ValidatorForm>
                </Grid>
  
      </div>
  )
  
  
  }
