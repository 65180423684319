import React, { useState ,useEffect} from 'react';
import {useCookies} from 'react-cookie'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import jsPDF from 'jspdf'
import logo from '../../assets/Images/dec6.png'
import halodig from '../../assets/Images/HaloPurp.png'
import { Grid ,Button,Box} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default function Payments(props) {
  const{live}=props
    const[auth]=useCookies(['auth'])
    const[filter,setFilter]=useState([])
    const [status,setStatus]=useState(false)


    useEffect(() => {
        if(!auth.auth){
          window.location.href='/signin'
        }
         }, [auth.auth])
    useEffect(() => {
        if(auth.auth){
         fetch(live+`p-list?q=${auth.auth.memberID}`,
        { method: 'GET',
        headers:{
          'Content-Type' :'application/json'  
        }})
        //.then(resp=>console.log(resp))

        .then(res => res.json())
        .then(resp=>Filter(resp))
     } }, [auth.auth])
     const Filter=(resp)=>{
      setFilter(resp)
      setStatus(true)
    }
     const pdfGenerate=(mem)=>{
   
       var doc=new jsPDF('p','px','a4','false') 
      doc.setFont('Helvetica','bold') 
  doc.setFontSize(18)
      doc.setTextColor('#e1163c') 
  doc.text(40,80,'HaloDigital') 
     
      doc.setTextColor('#7f7f7f') 
      doc.setFontSize(12)
       doc.text(40,100,'(by Dec6 Consulting Private Limited)') 
      doc.setFontSize(14)
      doc.text(40,120,'Tax Invoice') 
      doc.setTextColor('#61126b') 
      doc.setFont('Helvetica','lighter') 
  //     doc.text(40,85,'Sector-11, Gurugram') 
  //     doc.text(40,100,'Haryana') 
  //     doc.text(40,115,'India') 
      doc.setFontSize(14) 
      doc.addImage(halodig,'PNG',370,60,50,50) 
      doc.setTextColor('#e1163c') 
     doc.text(40,180,'Bill to / Ship to :') 
    doc.setFontSize(12) 
    doc.setTextColor('#61126b') 
    doc.text(40,192,auth.auth.fname+' '+auth.auth.lname) 
    doc.text(40,204,auth.auth.addLine1) 
    doc.text(40,216,auth.auth.city+', '+auth.auth.stat) 
    doc.text(40,228,auth.auth.country) 
doc.text(40,240,(auth.auth.gstNumber?`GSTIN:${auth.auth.gstNumber}`:"")) 

      doc.setFontSize(12) 
      doc.text(300,180,'Invoice No') 
      doc.setTextColor('#7f7f7f') 
      doc.text(375,180,mem.paymentID.toString()) 
      doc.setTextColor('#61126b') 
      doc.text(300,192,'Invoice Date') 
      doc.setTextColor('#7f7f7f') 
      doc.text(375,192,mem.addedOn.toString()) 

  doc.setTextColor('#61126b') 
      doc.text(300,204,'HSN Code') 
      doc.setTextColor('#7f7f7f') 
      doc.text(375,204,'998314') 
  doc.setTextColor('#61126b') 
      doc.text(300,216,'Place of supply') 
      doc.setTextColor('#7f7f7f') 
      doc.text(375,216,auth.auth.stat) 
  //     doc.setTextColor('#61126b') 
  //     doc.text(300,200,'Amount Due') 
  //     doc.setTextColor('#7f7f7f') 
  //     doc.text(375,200,mem.amount.toString()) 
 
  // doc.addImage(halodig,'PNG',40,250,50,50) 
  // doc.setFont('Helvetica','bold') 
  //     doc.setFontSize(14) 
  // doc.setTextColor('#7f7f7f') 
  // doc.text(100,290,'HaloDigital') 
  // doc.setFontSize(12)
  // doc.setFont('Helvetica','lighter') 
    doc.setTextColor('#61126b') 
    doc.text(40,330,'Plan') 
    doc.setTextColor('#7f7f7f') 
    doc.text(40,350,mem.plan.toUpperCase()) 
    doc.setTextColor('#61126b') 
    doc.text(100,330,'Description') 
    doc.setTextColor('#7f7f7f') 
    doc.text(100,350,mem.category=='business profile'?(mem.category.toString().toUpperCase()):(mem.category.toString().toUpperCase()+' - '+mem.plan.toUpperCase())) 
    doc.setTextColor('#61126b') 
    doc.text(220,330,'Product Cost') 
    doc.setTextColor('#7f7f7f') 
    doc.text(220,350,mem.currency+' '+(mem.productAmount?mem.productAmount:mem.amount)) 
    

    doc.setTextColor('#61126b') 
    doc.text(285,330,(mem.CGST?(`SGST(${(parseInt(mem.taxRate))/2}%)`):'')) 
    doc.setTextColor('#7f7f7f') 
    doc.text(285,350,(mem.CGST?(mem.currency+' '+mem.CGST):'') )
     doc.setTextColor('#61126b') 
    doc.text(345,330,(mem.SGST?(`CGST(${(parseInt(mem.taxRate))/2}%)`):'')) 
    doc.setTextColor('#7f7f7f') 
    doc.text(345,350,(mem.SGST?(mem.currency+' '+mem.SGST):'') )
     doc.setTextColor('#61126b') 
    doc.text(285,330,mem.IGST?(`IGST(${mem.taxRate}%)`):"") 
    doc.setTextColor('#7f7f7f') 
    doc.text(285,350,mem.IGST?(mem.currency+' '+mem.IGST):'')
    


    doc.setTextColor('#61126b') 
    doc.text(mem.SGST?395:mem.IGST?345:285,330,'Total*') 
    doc.setTextColor('#e1163c') 
//     doc.text(415,330,'**') 
    doc.setTextColor('#7f7f7f') 
    doc.text(mem.SGST?395:mem.IGST?345:285,350,mem.currency+' '+mem.amount) 
    doc.setTextColor('#e1163c') 
    doc.setFontSize(11) 
//     doc.text(40,500,'*This is a computer generated invoice') 
    doc.setTextColor('#e1163c') 
    doc.text(40,490,'*Rounded off value') 
    doc.text(40,520,'Note: Unless otherwise stated, tax on this invoice is not payable under reverse charge.')
    doc.text(40,500,'This is a computer generated invoice')
    doc.setTextColor('#5f5f5f') 
    doc.setFontSize(11) 
    doc.setFont('Helvetica','lighter') 
    doc.text(40,550,'Dec6 Consulting Private Limited') 
    doc.text(40,562,'H.No. 2/62, Ashri Bhawan, Shivaji Nagar') 
    doc.text(40,574,'Gurugram, Haryana, India - 122001') 
//     doc.text(160,580,'©2021 Dec6 Consulting Private Limited') 
    doc.text(40,586,'GSTIN: 06AAHCD9132C1ZX')
    
//     doc.text(172,592,'CIN: U72900HR2020PTC088377') 
//     doc.text(178,604,'GSTIN: 06AAHCD9132C1ZX') 
//     doc.text(190,616,'Email: info@dec6.in ') 
//  doc.text(40,592,'CIN: U72900HR2020PTC088377') 
   doc.text(40,598,'Email: info@dec6.in ')
    doc.setTextColor('#6f6f6f') 
    doc.setFontSize(10)
     doc.text(160,616,'©2025 Dec6 Consulting Private Limited') 
      doc.save('invoice.pdf')
    }


           

  
  const classes = useStyles();
  if(auth.auth&&status&&filter[0])

  return (<>
      <div className={classes.root}>
      {filter.sort((a, b) => a.paymentID < b.paymentID ? 1 : -1).map((fil,idx)=>{
          return(
            <Accordion key={idx}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Payment ID: {fil.paymentID}</Typography>
            </AccordionSummary>
          
            <AccordionDetails>
            
              <Typography>
               Email: {fil.email}
              </Typography>
            
            </AccordionDetails>
            <AccordionDetails>
             
              <Typography>
               Amount: { fil.currency+ fil.amount} 
              </Typography>
           
            </AccordionDetails>
            <AccordionDetails>
            <Typography>
              <Grid container>
              <Grid item>
              Paid On: {fil.addedOn} 

              </Grid>
              <Grid style={{alignSelf:'center'}} item>
              <Button  variant="outlined" onClick={()=>pdfGenerate(fil)} style={{backgroundColor:'#61126b',height:'25px',fontSize:'12px',alignItems:'center',marginLeft:'30px'}}>
                  Download 
                  </Button>

              </Grid>
               </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          )
      })}
      </div>
      </>
  )
  if(status&&!filter[0]){
  
    return(   
      <div>          
      <h1 style={{fontWeight:'bold',textAlign:'center',marginTop:'25px'}}>No Payments found</h1>
      </div>)
  
  }
  if(!status){
  
    return(   
        <Box sx={{ display: 'flex',justifyContent:'center' ,paddingTop:'20px'}}>
        <CircularProgress />
      </Box>)
  
  }
}