import React, { useState ,useEffect,useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import WelcomeS from './Home_WelcomeSection/D6_SL_WS';
// import WCUS from './Home_WhyChooseUsS/D6_SL_WCU';
import {Grid,Container, Button, Chip} from '@material-ui/core';
import HIcon from '../../assets/Images/halo-icon.svg';
import HIcon0 from '../../assets/Images/Templat1.png';
import HIcon1 from '../../assets/Images/Templat2.png';
import HIcon2 from '../../assets/Images/Templat3.png';
import Image from '../../assets/Images/cover.jpg';
import SettingsIcon from '@material-ui/icons/ViewCarousel';
import GroupAddIcon from '@material-ui/icons/RecentActors';
import Contactless from '@material-ui/icons/Contactless';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

import D6_Home_Main from './Home_below_navbar/home_main';

import SubCardSelect from '../ContactPage/D6_halo_subcat'
import {useCookies} from 'react-cookie'

import Dialog from '@material-ui/core/Dialog';
import D6_FE_AccountBox from '../SignUPForm/D6_FE_AccountBox';
import PreviewPltFormPlt from '../ContactPage/D6_halo_pltpreviewForm';
import jsPDF from 'jspdf'
import logo from '../../assets/Images/HaloFIcon.png'
import D6_SL_Footer from '../common/Footer/D6_SL_Footer';
import Testi_Crousel from './testimonials/testi_crousel';
import { getBottomNavigationUtilityClass } from '@mui/material';
import D6_SL_AF from '../common/AboveFooter/D6_SL_AF';
import Halo_Pass_Template from './Home_WelcomeSection/Halo_Pass';
// import Video1 from '../../assets/Images/video.mp4'
import Video1 from '../../assets/Images/HaloHIW.mp4'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
//   const useStyles = makeStyles((theme) => ({
//     root: {
//       flexGrow: 1,
//       backgroundColor: theme.palette.background.paper,
//     },
//   }));

const useStyles = makeStyles((theme) => ({

    'D6_SL_WS_Cont' : {
       marginTop: "20px",
   },
    'D6_SL_WS_Grid1_Div' : {
       textAlign: "center",
       alignItems: "center",
       marginBottom: "20px",
       color: "#36444e",
   },
    'D6_SL_WS_Grid2_DivHI' : {
       marginTop: '0px',
   },
    'D6_SL_WS_Grid1_Div_H1' : {
    fontSize: '48px',
    fontWeight: '300',
    color: '#36444e',
    lineHeight: '48px',
    display: 'block',
    margin:'0 0 30px',
    textAlign: 'center',
    '& span': {
        fontSize: '36px',
    },
   },
   'D6_SL_WS_Grid2_container': {
       width: '100%',
      //  display: 'flex',
      //  margin: '40px 0',
       textAlign: 'center',
       [theme.breakpoints.down('sm')]: {
           flexDirection: 'column',
         },
   },
    'D6_SL_WS_Grid2_Div' : {
       width: "100%",
       margin: "0px auto",
       [theme.breakpoints.down('sm')]: {
           marginBottom: '20px',
         },
   },
   'D6_SL_WS_Grid2_HDiv' : {
       width: "100%",
       borderBottom: "2px solid var(--ycolor)",
       marginBottom: "10px",
       display: 'flex',
   },
   'D6_SL_WS_HIcon': {
      //  display: 'inline-flex',
       width: '40px',
       height: '40px',
   },
   'D6_SL_WS_Grid2_Div_H1' : {
       fontSize: "30px",
       fontWeight: '300',
       color: "#36444e",
       lineHeight: '40px',
       paddingLeft: '20px',
       paddingBottom: '10px',
      //  display: 'inline-flex',
       textAlign: 'left',
   },
   'D6_SL_WS_Grid2_Div_P' : {
       fontSize: "18px",
   },
   }));

const D6_SL_Home = (props) => {


  const{live,countryF}=props

const [active,setActive]=useState('card')

  useEffect(() => {
    let search = window.location.search;
  let params = new URLSearchParams(search);
  let foo = params.get('id');
  let foo1=params.get('id1');
  let foo2=params.get('city');
  let foo3=params.get('state');
  let foo4=params.get('country');


  if(foo&&foo1&&foo2&&foo3&&foo4){
    fetch(live+`p-list?q=${foo}`, 
    { method: 'GET',
    headers:{
      'Content-Type' :'application/json'  
    }})
    //.then(resp=>console.log(resp))

    .then(res => res.json())
    .then(resp=>Filter(resp))

  //   if(filter&&filter[0]){
  //     console.log('filter',filter)
  //  filterVal(foo1,foo2,foo3,foo4)
  // }
  const Filter=(resp)=>{

    if(resp&&resp[0]){

      filterVal(resp,foo1,foo2,foo3,foo4)
    }

  }
    //  

  }
  

    
  }, [])
  async function filterVal(filter,foo1,foo2,foo3,foo4){
    let filter2=await filter.filter((id)=>(id.stripeID.includes(foo1)))
    if(filter2[0]){
         var doc=new jsPDF('portrait','px','a4','false') 
        doc.setFont('Helvetica','bold') 
        doc.setTextColor('#e1163c') 
        doc.text(30,70,'Dec6 Consulting Private Limited') 
        doc.setTextColor('#61126b') 
        doc.setFont('Helvetica','lighter') 
    //     doc.text(30,85,'Sector-11, Gurugram') 
    //     doc.text(30,100,'Haryana') 
    //     doc.text(30,115,'India') 
        doc.setFontSize(14) 
        doc.addImage(logo,'PNG',370,60,50,50) 
        doc.setTextColor('#e1163c') 
        doc.text(30,180,filter2[0].name) 
        doc.setFontSize(12) 
        doc.setTextColor('#61126b') 
        doc.text(30,190,foo2+', '+foo3) 
        doc.text(30,200,foo4) 
        doc.setFontSize(12) 
        doc.text(300,180,'PaymentID') 
        doc.setTextColor('#7f7f7f') 
        doc.text(375,180,filter2[0].paymentID.toString()) 
        doc.setTextColor('#61126b') 
        doc.text(300,190,'Invoice Date') 
        doc.setTextColor('#7f7f7f') 
        doc.text(375,190,filter2[0].addedOn.toString()) 
        doc.setTextColor('#61126b') 
        doc.text(300,200,'Amount Due') 
        doc.setTextColor('#7f7f7f') 
        doc.text(375,200,filter2[0].amount.toString()) 
        doc.setTextColor('#61126b') 
        doc.text(30,270,'Plan') 
        doc.setTextColor('#7f7f7f') 
        doc.text(30,310,filter2[0].plan.toUpperCase()) 
        doc.setTextColor('#61126b') 
        doc.text(130,270,'Description') 
        doc.setTextColor('#7f7f7f') 
        doc.text(130,310,filter2[0].category.toUpperCase()+' - '+filter2[0].plan.toUpperCase()) 
        doc.setTextColor('#61126b') 
        doc.text(275,270,'Product Cost') 
        doc.setTextColor('#7f7f7f') 
        doc.text(275,310,filter2[0].currency+' '+filter2[0].amount) 
        // doc.setTextColor('#61126b') 
        // doc.text(399,270,'Tax') 
        // doc.setTextColor('#AAAAAA') 
        // doc.text(399,310,'$15.12') 
        doc.setTextColor('#61126b') 
        doc.text(375,270,'Total') 
        doc.setTextColor('#7f7f7f') 
        doc.text(375,310,filter2[0].currency+' '+filter2[0].amount) 
        doc.setTextColor('#e1163c') 
        doc.text(30,420,'*This is a computer generated invoice') 
        doc.setTextColor('#7f7f7f') 
        doc.setFontSize(12) 
        doc.setFont('Helvetica','lighter') 
        doc.text(160,580,'©2025 Dec6 Consulting Private Limited') 
        doc.text(172,592,'CIN: U72900HR2020PTC088377') 
        doc.text(190,604,'Email: info@dec6.in ') 
   
        doc.save('invoice.pdf')
        window.location.href='/'
    }
else{
  window.location.href='/'
}

  }
  const myRef = useRef(null)


  const[auth]=useCookies(['auth'])
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openi, setOpeni] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const [open3, setOpen3] = React.useState(false);
    const [openP, setOpenP] = React.useState(false);



    
      const handleClose = () => {
        setOpen(false);
        setOpeni(false)
        setOpen2(false)
        setOpen3(false)
        setOpenP(false)


      };
    const img0=HIcon0
    const img1=HIcon1
    const img2=HIcon2
  const [value, setValue] = React.useState(0);
  const [category,setCategory]=useState('platinum')
  const [cardScreen,setCardScreen]=useState(null)
  const [card,setCard]=useState(null)

  // useEffect(() => {
  //  if(!auth){
  //    setCategory(null)
  //  }
  // }, [auth])
  const cardForm=()=>{
    setCardScreen(true)
}
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const card2=value=>{
    if(auth.auth){
    setCard(value)}
    else{
      setOpen(true)

    }
  }
 
  const Category=(value)=>{

    setCategory(value)
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  


    setCard(null)
  

  }
useEffect(() => {
  if(auth.auth){
    if(auth.auth.minThresh&&auth.auth.minThresh<11)
    {
      setOpen2(true)
    }
    if(!auth.auth.planCategory){
      setOpen3(true)
    }
  }
 
}, [auth.auth])

  const Change=()=>{
    setCard(null)
  }
  const Product=(val)=>{
    if(val=='profile'||val==='passes'){
      setCategory('')
      setCard('')
      setActive(val)
    }

setActive(val) 
setCategory('platinum')

}
console.log(card,category)
const businessClicked=()=>{

  if(!auth.auth){
    setOpen(true)
  }
  else{
    window.location.href='/dash'
  }
}
const passClicked=()=>{
  if(!auth.auth){
    setOpen(true)
  }
  if(auth.auth){
    if(auth.auth.passSubscription){
      window.location.href='/dash'
    }
    else{
      setOpenP(true)
    }
  }
}
    return (
        <>
            {/* <Carousel/>     */}
            {/* <div>
            <div className="color-overlay"></div>
            </div> */}
            <D6_Home_Main  />

            <WelcomeS Product={Product}/>
            <Dialog
disableScrollLock={ true }
open={open}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
<D6_FE_AccountBox live={live} handleClose={handleClose} />     
    </Dialog>
    <Dialog
      open={openi}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      disableScrollLock={ true }
      aria-labelledby="draggable-dialog-title"
    >
<img src={Image}/>
    </Dialog>
            <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>Your plan {auth&&auth.auth&&auth.auth.minThresh>0?'will expire soon':'is expired'}, please recharge or upgrade your plan!</strong>
      </Alert>
      </Snackbar>


      <Snackbar open={openP}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>Halo Pass Subscription is not purchased, please purchase Halo Passes Subscription from pricing page</strong>
      </Alert>
      </Snackbar>

      <Snackbar open={open3}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="info">
        <AlertTitle>Welcome</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>Great stuff, please select a plan from pricing page</strong>
      </Alert>
      </Snackbar>
          
{     !cardScreen?    <Container  fixed className={classes['D6_SL_WS_Cont']}>
            <Grid container justify="center" alignItems="center" fixed className={classes['D6_SL_WS_Grid']}>
           {active=='card'? <Grid container item xs={12} className="D6_Halo_WS_MainCont">
                            <Grid container item sm={4} xs={12} className="D6_Halo_WS_PF">
                                <Grid item sm={4} xs={4} className="D6_Halo_IconsPF">
                                  Choose
                                <SettingsIcon />
                                </Grid>
                                <Grid item sm={8} xs={8}>
                                <div className="D6_Halo_WS_PFTextHead">Choose your card</div>                                    <div>
                                        <ul>
                                            {/* <li className="D6_Halo_WS_PFTextDesc">Classic</li> */}
                                            {/* <li className="D6_Halo_WS_PFTextDesc">Signature</li> */}
                                            <li className="D6_Halo_WS_PFTextDesc">Platinum</li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container item sm={4} xs={12} className="D6_Halo_WS_PF">
                                <Grid item sm={4} xs={4} className="D6_Halo_IconsPF">
                                     Create 
                                    <GroupAddIcon/>
                                </Grid>
                                <Grid item sm={8} xs={8}>
                                    {/* <Paper> */}
                                    <div className="D6_Halo_WS_PFTextHead">Personalise your digital card</div>
                                    <div>
                                        <ul>
                                            <li className="D6_Halo_WS_PFTextDesc">Add your contact details</li>
                                            <li className="D6_Halo_WS_PFTextDesc">Upload your Brand Logo/Image</li>
                                            <li className="D6_Halo_WS_PFTextDesc">Add social media profiles</li>
                                        </ul>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                            <Grid container item sm={4} xs={12} className="D6_Halo_WS_PF">
                                <Grid item sm={4} xs={4} className="D6_Halo_IconsPF">
                                     Share 
                                    <Contactless />
                                </Grid>
                                <Grid item sm={8} xs={8}>
                                <div className="D6_Halo_WS_PFTextHead">Connect digitally</div>
                                    <div>
                                        <ul>
                                            <li className="D6_Halo_WS_PFTextDesc">Scan QR Code &amp; share</li>
                                            <li className="D6_Halo_WS_PFTextDesc">Unique Web URL</li>
                                            <li className="D6_Halo_WS_PFTextDesc">Go digital, expand your reach</li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>:null}
            
            {active=='card'?
            <div id='mainCat' className={classes['D6_SL_WS_Grid2_container']}>
              {/* <Grid container>
                <Grid sm={12} xs={12}>
            <div className="D6_Halo_WS_MainCont">
                        <div  style={{paddingTop:'10px',marginTop:'8px'}} className="D6_Halo_WS_PFHead">HOW IT WORKS !!</div>
                        </div>
                        </Grid>
                        <Grid sm={12} xs={12}>
                        <video   className='video_HIW'  muted loop src={Video1} type="video/mp4" autoPlay={true}  />
                        </Grid>
                        </Grid> */}
     <Grid container style={{padding:'2vh'}}>
                {/* <Grid onClick={()=>{Category('classic')}} item md={6} xs={12} className={classes['D6_SL_WS_Grid2']} >
                  <Button >
                    <div className={classes['D6_SL_WS_Grid2_Div']}>
                        <div className={classes['D6_SL_WS_Grid2_HDiv']}>
                            <div className={classes['D6_SL_WS_HIcon']}>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className={classes['D6_SL_WS_Grid2_Div_H1']}>Halo Classic</Typography>
                        </div>
                       <Paper elevation={6} className="D6_Halo_CardsCont">
                        
                          <Grid >
                            <ul>
                              <li className="D6_Halo_CardsDesc">
                                Personalise your digital card
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Enriched with social media information
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                All data secured on AWS
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Works seamlessly on all devices
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Share easily with unique URL, QR Code, Email &amp; Text
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={12} className="D6_Halo_CardsGridSelect1">
                            <Paper elevation={6} className="D6_Halo_CardsSelect">
                              Create your digital card
                            </Paper>
                          </Grid>
                       </Paper>
                    </div>
                    </Button>
                </Grid> */}
                {/* <Grid  onClick={()=>{Category('signature')}}item md={4} xs={12} className={classes['D6_SL_WS_Grid2']} >
                <Button>

                    <div className={classes['D6_SL_WS_Grid2_Div']}>
                        <div className={classes['D6_SL_WS_Grid2_HDiv']}>
                            <div className={classes['D6_SL_WS_HIcon']}>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className={classes['D6_SL_WS_Grid2_Div_H1']}>Halo Signature</Typography>
                        </div>
                        <Paper elevation={6} className="D6_Halo_CardsCont">
                        
                          <Grid >
                            <ul>
                              <li className="D6_Halo_CardsDesc">
                                Edit/update your information anytime
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Enhanced information incl. location, headline
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Customised dashboard
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Works seamlessly on all devices
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Share easily with unique URL, QR Code, Email &amp; Text
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={12} className="D6_Halo_CardsGridSelect">
                            <Paper elevation={6} className="D6_Halo_CardsSelect">
                              Create your digital card
                            </Paper>
                          </Grid>
                       </Paper>
                    </div>
                    </Button >

                </Grid> */}
                {/* <Grid onClick={()=>{Category('platinum')}} item md={6} xs={12} className={classes['D6_SL_WS_Grid2']} >
                  <Button>
                    <div className={classes['D6_SL_WS_Grid2_Div']}>
                        <div className={classes['D6_SL_WS_Grid2_HDiv']}>
                            <div className={classes['D6_SL_WS_HIcon']}>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className={classes['D6_SL_WS_Grid2_Div_H1']}>Halo Platinum</Typography>
                        </div>
                        <Paper  elevation={6} className="D6_Halo_CardsCont">
                          
                          <Grid  >
                            <ul>
                            <li className="D6_Halo_CardsDesc">
                                Customizable cover pic, display business hours
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                More social media profiles (incl. 3 pay options)
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Additional notes for more information
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Search through your Halo Cards &amp; Connections
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Switch &amp; change your platinum card template anytime
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={12} className="D6_Halo_CardsGridSelect1">
                            <Paper elevation={6} className="D6_Halo_CardsSelect">
                              Create your digital card
                            </Paper>
                          </Grid>
                       </Paper>
                    </div>
                    </Button>
                </Grid> */}
                </Grid>
                {/* <Grid container>
                <Grid sm={12} xs={12}>
            <div className="D6_Halo_WS_MainCont">
                        <div  style={{paddingTop:'10px',marginTop:'8px'}} className="D6_Halo_WS_PFHead">HOW IT WORKS !!</div>
                        </div>
                        </Grid>
                        <Grid sm={12} xs={12}>
                        <video   className='video_HIW'  muted loop src={Video1} type="video/mp4" autoPlay={true}  />
                        </Grid>
                        </Grid> */}
                </div>:null}
               
                {active=='profile'?<div id='mainCat'  className={classes['D6_SL_WS_Grid2_container']}>
                <Grid container  style={{justifyContent:'center'}}>
                <Grid  item md={12} xs={12} className={classes['D6_SL_WS_Grid2']} >
                  <Button>
                    <div className={classes['D6_SL_WS_Grid2_Div']}>
                        <div className={classes['D6_SL_WS_Grid2_HDiv']}>
                            <div className={classes['D6_SL_WS_HIcon']}>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className={classes['D6_SL_WS_Grid2_Div_H1']}>Halo Businee</Typography>
                        </div>
                        {/* <SimpleCard img={img2}/> */}
                        <Paper  elevation={6} className="D6_Halo_CardsCont">
                         
                          <Grid  >
                            <ul>
                            <li className="D6_Halo_CardsDesc">
                            Digital catalogue for your products/services
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Publish your products anytime
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Add your order link to products
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Categorize your product listing
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Followers receive notifications for any updates
                              </li>
                            </ul>


                          </Grid>
                          <Grid container  className="D6_Halo_CardsGridSelect1">
                            <Grid   item sm={6} xs={6}>
                            < Chip style={{cursor:'pointer'}}  color="secondary" label="Live Preview"  />

                            </Grid>
                            <Grid style={{textAlign:'initial',cursor:'pointer'}} onClick={()=>businessClicked()} item sm={6} xs={6}>
                            < Chip  style={{cursor:'pointer'}}  color="secondary" label="Create Profile"  />

                            </Grid>
                            {/* <Paper elevation={6} className="D6_Halo_CardsSelect">
                              Create your digital card
                            </Paper> */}
                          </Grid>
                       </Paper>
                    </div>
                    </Button>
                </Grid>
                </Grid>
                </div>:null}
                {active=='passes'?<div id='mainCat' className={classes['D6_SL_WS_Grid2_container']}>
                <Grid container>
                <Grid  item md={6} xs={12} className={classes['D6_SL_WS_Grid2']} >
                  <Button >
                    <div className={classes['D6_SL_WS_Grid2_Div']}>
                        <div className={classes['D6_SL_WS_Grid2_HDiv']}>
                            <div className={classes['D6_SL_WS_HIcon']}>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className={classes['D6_SL_WS_Grid2_Div_H1']}>Halo SCAN & GO </Typography>
                        </div>
                      
                       <Paper elevation={6} className="D6_Halo_CardsCont">
                 
                          <Grid >
                            <ul>
                              <li className="D6_Halo_CardsDesc">
                              Single use digital passes
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Customise as per your needs                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Re-usable for returning clients
                              </li>
                              
                              <li className="D6_Halo_CardsDesc">
                                Track & Analyse Smart Passes
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Share easily with unique URL, QR Code, Email &amp; Text
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={12} className="D6_Halo_CardsGridSelect1">
                            <Paper elevation={6} onClick={()=>passClicked()} className="D6_Halo_CardsSelect">
                            Generate Passes
                              {/* <i class="fas fa-arrow-circle-down 4x"></i> */}
                            </Paper>
                          </Grid>
                       </Paper>
                    </div>
                    </Button>
                </Grid>
                
                <Grid  item md={6} xs={12} className={classes['D6_SL_WS_Grid2']} >
                  <Button>
                    <div className={classes['D6_SL_WS_Grid2_Div']}>
                        <div className={classes['D6_SL_WS_Grid2_HDiv']}>
                            <div className={classes['D6_SL_WS_HIcon']}>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className={classes['D6_SL_WS_Grid2_Div_H1']}>Halo Smart pass</Typography>
                        </div>
                        {/* <SimpleCard img={img2}/> */}
                        <Paper  elevation={6} className="D6_Halo_CardsCont">
                           {/* <Grid className="D6_Halo_CardsHead">
                          Halo Platinum
                          </Grid> */}
                          <Grid  >
                            <ul>
                            <li className="D6_Halo_CardsDesc">
                            Manage your client's memberships/subscriptions 
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Multiple use smart passes
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Re-usable for clients
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Go Digital, save printing hassles
                              </li>
                              <li className="D6_Halo_CardsDesc">
                              Track & Analyse Smart Passes                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={12} className="D6_Halo_CardsGridSelect1">
                            <Paper elevation={6} onClick={()=>passClicked()} className="D6_Halo_CardsSelect">
                              Generate Subscriptions
                            </Paper>
                          </Grid>
                       </Paper>
                    </div>
                    </Button>
                </Grid>
    </Grid>
                </div>:null}


            </Grid>
        </Container>
:null}
{active==='card'?<div ref={myRef}>
            {/* <WCUS/> */}{category=='classic'&&!cardScreen||category=='signature'&&!cardScreen||category=='platinum'&&!cardScreen ?
            <SubCardSelect  live={live} category={category} card2={card2} />:null}</div>:null}
             {/* <SubCategory  cardForm={cardForm}category={category}img0={img0} img1={img1}img2={img2} />:null} */}

            {card?
            <PreviewPltFormPlt Change={Change} live={live}category={category} countryF={countryF} card={card} />:null}
{/* <Testi_Crousel /> */}

            {/* <Transformation /> */}
            {/* <div style={{marginTop:'20px',marginBottom:'20px',justifyContent:'center',marginLeft:'30px'}}>
            <Halo_Pass_Template/>
            </div> */}

     {active==='card'?
      <Container  fixed className={classes['D6_SL_WS_Cont']}>
            <Grid container justifyContent="center" alignItems="center" fixed className={classes['D6_SL_WS_Grid']}>
     <Grid container>
     <Grid sm={12} xs={12}>
 <div className="D6_Halo_WS_MainCont">
             {/* <div className="D6_Halo_WS_PFHead">HOW IT WORKS !</div> */}
             <div  style={{paddingTop:'10px',marginTop:'8px',textAlign:'center'}} className="D6_Halo_WS_PFHead">HOW IT WORKS !!</div>
             </div>
             </Grid>
             <Grid sm={12} xs={12}>
             <video   className='video_HIW'  muted loop src={Video1} type="video/mp4" autoPlay={true}  />
             </Grid>
             </Grid>
             </Grid>
            </Container>
     :null}
            <D6_SL_AF />
            <D6_SL_Footer />
           
        </>
    )
}

export default D6_SL_Home
