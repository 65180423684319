import React,{useEffect,useRef,useState} from 'react'

import './D6_halo_pricing.css'

import Dialog from '@material-ui/core/Dialog';
import Pricingplt from './D6_halo_pricingplt';
// import Pricingsig from './D6_halo_pricingsig';
import Pricingcls from './D6_halo_pricingcls';

import {useCookies} from 'react-cookie'
import StripePay from '../Payments/stripePay';
import jsPDF from 'jspdf'
import logo from '../../assets/Images/HaloFIcon.png'
import halodig from '../../assets/Images/HaloPurp.png'
// import RazorPay from '../Payments/razorPay';
import D6_SL_Footer from '../common/Footer/D6_SL_Footer';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Grid ,Button,Container,Paper} from '@material-ui/core';
// import { D6_halo_Pcat } from './D6_halo_Pcat';
import { Typography } from '@mui/material';
import Pricing_Combo from './D6_halo_pricing_combo';
import Pricing_Profile from './D6_halo_pricing_Profile';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

import './D6_halo_Pcat.css'
import D6_SL_AF from '../common/AboveFooter/D6_SL_AF';
import Pricing_Pass from './D6_halo_passP';
import TopUp from './D6_halo_topUp';

export default function Pricing(props) {

    const {countryF,live}=props

    const[auth,setAuth]=useCookies(['auth'])
const [country,setCountry] = React.useState([]);

    const [open, setOpen] = React.useState(false);
    const[cat,setCat]=React.useState(null);
    const[plan,setPlan]=React.useState(null);
    const[filter,setFilter]=React.useState([]);

    const[change,setChange]=React.useState(null);
    const [success,setSuccess]=React.useState(null)
    const [pricing,setPricing]=useState('1')
const [scroll,setScroll]=useState(false)
const [open1,setOpen1]=useState(false)
const [open2,setOpen2]=useState(false)
const [available,setAvailable]=useState([])
const [cards,setCards]=useState([])
const [Pmember,setPMember]=useState([])
const [down,setDown]=useState(false)
const [current,setCurrent]=useState(false)

//     const oneDay1 = 24 * 60 * 60 * 1000; 
// const firstDate1= new Date(auth.auth.createdDate);
// const secondDate1 = new Date(auth.auth.expiryDate);

// const diffDays1 = Math.round(Math.abs((firstDate1 - secondDate1) / oneDay1));
// console.log('diffDays',diffDays1)
const[template,setTemplate]=useState('')



useEffect(() => {
  let search = window.location.search;
let params = new URLSearchParams(search);
let foo = params.get('q');
let foo1=params.get('q1');
if(foo){
  setOpen(true)
  let bodyData = new FormData();

    bodyData.append("orderID",foo);
    fetch(live+"handlepayment",{

            

                method:'PUT',
           
                body:bodyData
                })
                .then(res=>res.json())
                .then((resp)=>responseApi(resp))
                .catch(error=>console.log(error))
}
if(foo1){
  setOpen(true)
  setSuccess('0')

}

},[])


useEffect(() => {
  if(auth.auth){
  fetch(live+`cards?q=${auth.auth.memberID}`,
 //  fetch(`http://127.0.0.1:8000/cards?q=${auth.auth.memberID}`,
  { method: 'GET',
  headers:{
    'Content-Type' :'application/json'  
  },})
  .then(res => res.json())
  .then(resp=>setCards(resp))
}}, [auth.auth])

useEffect(() => {
  if(auth.auth){
  fetch(live+`memberRef?q=${auth.auth.memberID}`,
 //  fetch(`http://127.0.0.1:8000/cards?q=${auth.auth.memberID}`,
  { method: 'GET',
  headers:{
    'Content-Type' :'application/json'  
  },})
  .then(res => res.json())
  .then(resp=>setPMember(resp))
  
}}, [auth.auth])


useEffect(() => {
     
  if(auth.auth&&auth.auth.passSubscription){
    fetch(live+`passTemplate?q=${auth.auth.memberID}`,
     { method: 'GET',
     headers:{
       'Content-Type' :'application/json'  
     },})
     .then(res => res.json())
     .then(resp=>setTemplate(resp.data))
  }
   }, [auth.auth])
const Pricing=(val)=>{
  setScroll(true)
  setPricing(val)

}

const Country=(val)=>{
  fetch(live+`c-list?q=${val}`,
  {
  method: 'GET',
  headers:{
    'Content-Type' :'application/json'  
  },
})
.then(res => res.json())
.then(resp=>setCountry(resp))
}
const responseApi=(resp)=>{
  if(resp.data){
    setSuccess('1')
    pdfGenerate(resp.data,resp.data1)
    
  
  //  alert('Success')
  }
  if(!resp.data){
setOpen(false)
  }
}
// const Data=(data,data)=>{
//   setAuth('auth',data,{
//     maxAge:3600 ,
//     expires:0
//   })
// }

    async function CountryFilter(){
      let filter1=await country.filter((count)=>

      (count.planCategory.toLowerCase().includes(cat))&&
      (count.planType.toLowerCase().includes(plan))
      // &&(count.countryName.toLowerCase().includes(auth.auth.country.toLowerCase()))
    
    
    
    )
    if(filter1[0]){
setFilter(filter1)}
else{
  setFilter(country.filter((count)=>
  (count.planCategory.toLowerCase().includes(cat))&&
  (count.planType.toLowerCase().includes(plan))
  // &&(count.countryName.toLowerCase().includes('usa'))
  ))
}
    }

    useEffect(() => {
      if(!auth.auth){
      
        fetch('https://ipapi.co/json/',
        {
        method: 'GET',
        headers:{
          'Content-Type' :'application/json'  
        },
      })
      .then(res => res.json())
      .then(resp=>Country1(resp))
      // .catch(err=>Country())
        // Country(val)

      
    }
      if(auth.auth){
      Country(auth.auth.country)
      
    }
      },[auth.auth])


      useEffect(() => {
        if(auth.auth){
        
          fetch(`${live}dcard_b?q=${auth.auth.memberID}`,
          {
          method: 'GET',
          headers:{
            'Content-Type' :'application/json'  
          },
        })
        .then(res => res.json())
        .then(resp=>setAvailable(resp))
        // .catch(err=>Country())
          // Country(val)
  
        
      }
        if(auth.auth){
        Country(auth.auth.country)
        
      }
        },[auth.auth])
    const Country1=(val)=>{
Country(val.country_name)
    }

    useEffect(()=>{ 
      if(country[0]&&auth.auth&&cat&&plan){


if(countryF){
  setFilter(country.filter((count)=>
  (count.planCategory.toLowerCase().includes(cat))&&
  (count.planType.toLowerCase().includes(plan))&&
  
  (count.countryName.toLowerCase().includes(countryF))



))



}
if(!countryF){
CountryFilter()

// if(!filter[0]){





// }

}
 
      }

else{
      setFilter([])
    }
          
      },[cat,plan,change,country,countryF,auth.auth])
   const pdfGenerate=(mem,payment)=>{
    setAuth('auth',mem,{
        maxAge:3600 ,
        expires:0
     })

     
     var doc=new jsPDF('portrait','px','a4','false') 
            doc.setFont('Helvetica','bold') 
     doc.setFontSize(18)
         doc.setTextColor('#e1163c') 
     doc.text(40,80,'HaloDigital') 
        
         doc.setTextColor('#7f7f7f') 
         doc.setFontSize(12)
          doc.text(40,100,'(by Dec6 Consulting Private Limited)') 
         doc.setFontSize(14)
         doc.text(40,120,'Tax Invoice') 
         doc.setTextColor('#61126b') 
         doc.setFont('Helvetica','lighter') 
     //     doc.text(40,85,'Sector-11, Gurugram') 
     //     doc.text(40,100,'Haryana') 
     //     doc.text(40,115,'India') 
         doc.setFontSize(14) 
    doc.addImage(halodig,'PNG',370,60,50,50) 
    doc.setTextColor('#e1163c') 
   doc.text(40,180,'Bill to / Ship to :') 
    doc.setFontSize(12) 
    doc.setTextColor('#61126b') 
    doc.text(40,192,auth.auth.fname+' '+auth.auth.lname) 
doc.text(40,204,auth.auth.addLine1) 
    doc.text(40,216,mem.city+', '+mem.stat) 
    doc.text(40,228,mem.country) 
doc.text(40,240,(auth.auth.gstNumber?`GSTIN:${auth.auth.gstNumber}`:"")) 

    doc.setFontSize(12) 
    doc.text(300,180,'Invoice No') 
    doc.setTextColor('#7f7f7f') 
    doc.text(375,180,payment.paymentID.toString()) 
    doc.setTextColor('#61126b') 
    doc.text(300,192,'Invoice Date') 
    doc.setTextColor('#7f7f7f') 
    doc.text(375,192,payment.addedOn.toString()) 
    doc.setTextColor('#61126b') 
    doc.text(300,204,'HSN Code') 
    doc.setTextColor('#7f7f7f') 
    doc.text(375,204,'998314') 
doc.setTextColor('#61126b') 
    doc.text(300,216,'Place of supply') 
    doc.setTextColor('#7f7f7f') 
    doc.text(375,216,mem.stat) 
//     doc.setTextColor('#61126b') 
//     doc.text(300,200,'Amount Due') 
//     doc.setTextColor('#7f7f7f') 
//     doc.text(375,200,payment.amount.toString()) 
    doc.setTextColor('#61126b') 
    doc.text(40,330,'Plan') 
    doc.setTextColor('#7f7f7f') 
    doc.text(40,350,payment.plan.toUpperCase()) 
    doc.setTextColor('#61126b') 
    doc.text(100,330,'Description') 
    doc.setTextColor('#7f7f7f') 
    doc.text(100,350,payment.category=='business profile'?(payment.category.toString().toUpperCase()):(payment.category.toString().toUpperCase()+' - '+payment.plan.toUpperCase())) 
    doc.setTextColor('#61126b') 
    doc.text(220,330,'Product Cost') 
    doc.setTextColor('#7f7f7f') 
    doc.text(220,350,payment.currency+' '+(payment.productAmount?payment.productAmount:payment.amount)) 
     doc.setTextColor('#61126b') 
    doc.text(285,330,(payment.CGST?(`SGST(${(parseInt(payment.taxRate))/2}%)`):'')) 
    doc.setTextColor('#7f7f7f') 
    doc.text(285,350,(payment.CGST?(payment.currency+' '+payment.CGST):'') )
     doc.setTextColor('#61126b') 
    doc.text(345,330,(payment.SGST?(`CGST(${(parseInt(payment.taxRate))/2}%)`):'')) 
    doc.setTextColor('#7f7f7f') 
    doc.text(345,350,(payment.SGST?(payment.currency+' '+payment.SGST):'') )
     doc.setTextColor('#61126b') 
    doc.text(285,330,payment.IGST?(`IGST(${payment.taxRate}%)`):"") 
    doc.setTextColor('#7f7f7f') 
    doc.text(285,350,payment.IGST?(payment.currency+' '+payment.IGST):'')
    


    doc.setTextColor('#61126b') 
    doc.text(payment.SGST?395:payment.IGST?345:285,330,'Total*') 
    doc.setTextColor('#7f7f7f') 
    doc.text(payment.SGST?395:payment.IGST?345:285,350,payment.currency+' '+payment.amount) 
    doc.setTextColor('#e1163c') 

  doc.setFontSize(11) 
//     doc.text(40,500,'*This is a computer generated invoice') 
    doc.setTextColor('#e1163c') 
    doc.text(40,490,'*Rounded off value') 
    doc.text(40,520,'Note: Unless otherwise stated, tax on this invoice is not payable under reverse charge.')
    doc.text(40,500,'This is a computer generated invoice')
    doc.setTextColor('#5f5f5f') 
    doc.setFontSize(11) 
    doc.setFont('Helvetica','lighter') 
    doc.text(40,550,'Dec6 Consulting Private Limited') 
    doc.text(40,562,'H.No. 2/62, Ashri Bhawan, Shivaji Nagar') 
    doc.text(40,574,'Gurugram, Haryana, India - 122001') 
//     doc.text(160,580,'©2021 Dec6 Consulting Private Limited') 
    doc.text(40,586,'GSTIN: 06AAHCD9132C1ZX')
    
//     doc.text(172,592,'CIN: U72900HR2020PTC088377') 
//     doc.text(178,604,'GSTIN: 06AAHCD9132C1ZX') 
//     doc.text(190,616,'Email: info@dec6.in ') 
//  doc.text(40,592,'CIN: U72900HR2020PTC088377') 
   doc.text(40,598,'Email: info@dec6.in ')
    doc.setTextColor('#6f6f6f') 
    doc.setFontSize(10)
     doc.text(160,616,'©2025 Dec6 Consulting Private Limited') 
    
    doc.save('invoice.pdf')
  }
    const stripeResponse=(value,billingDetails)=>{
        // fetch(' https://d6-halo.herokuapp.com/memInfo',
        fetch(live+'memInfo',

    {
    method: 'PUT',
    headers:{
    'Content-Type' :'application/json'  
    },
    body: JSON.stringify({memberID:auth.auth.memberID,planCategory:cat,planType:plan,fname:auth.auth.fname,lname:auth.auth.lname,email:auth.auth.email,phone:auth.auth.phonenumber,country:auth.auth.country?auth.auth.country:billingDetails.country,stte:auth.auth.stat?auth.auth.stat:billingDetails.stat,city:auth.auth.city?auth.auth.city:billingDetails.city,addLine1:auth.auth.stat?auth.auth.addLine1:billingDetails.address,addLine2:'  ',cards:filter[0].cards,current:current,down:down,gstNumber:billingDetails.gst})
})
.then(res => res.json())
.then((resp)=>updateRes(resp,value))
       
    }
    const updateRes=(resp,value)=>{
        if(resp.data){
          alert('Plan Updated')
                pdfGenerate(resp.data,value)
            
    
        }
        if(!resp.data){
           setOpen1(true)
        }
    }

   
    
    
      const category=(value)=>{
        setCat(value)
    }
      const updatePlan=(value)=>{
        
        setChange(value+'1')

          setPlan(value)

      }
      const PricingSc=()=>{

        setPlan(null)
        setCurrent(false)
        setDown(false)
      }


    //   const vidRef = useRef(null);      
// useEffect(() => {
//     if(vidRef){

//         vidRef.current.play();
//       }
   
// })

const handleClose1=()=>{
  setOpen1(false)
  setOpen2(false)
}

const downgrade=()=>{
  setDown(true)
}
const currentPlan=()=>{
  setCurrent(true)
}
     if(filter[0]&&live&&cat&&plan){
       return(
        <StripePay down={down} current={current} template={template} PricingSc={PricingSc} points={Pmember[0]} auth={auth.auth} filter={filter} live={live} stripeResponse={stripeResponse} cat={cat} plan={plan}  />  
       )
     }
     else{
    return (
        <>
         {/* <div style={{height:'700px',width:'100%'}}> 
            <video style={{width:'100%'}} ref={vidRef}>
      <source src={video1} type="video/mp4" />
    </video>
    </div> */}
            <Dialog
      disableScrollLock={ true }
      open={open}
      style={{textAlign:'center'}}
      
      aria-labelledby="draggable-dialog-title"
    >
      <div style={{height:'250px',width:'100%',alignSelf:'center',fontSize:'17px'}}> 
      {!success?<>
        <h1 style={{width:'350px',paddingTop:'20px'}} >Payment processing please wait..</h1>
        <CircularProgress  style={{marginTop:'50px'}} />
        </>:null}
        {success=='1'?<h1 style={{width:'350px',marginTop:'48px',fontWeight:'bold'}} >Payment success</h1> 
       :success=='0'?<h1 style={{width:'350px',marginTop:'48px',fontWeight:'bold'}} >Payment failed</h1> :null}

        {success? <div onClick={()=>window.location.href='/'} ><h2 style={{width:'350px',paddingTop:'80px',color:"#61126b"}} >Back to home</h2> 

       
       <KeyboardBackspaceIcon   style={{marginTop:'10px',fontSize:'35px',cursor:'pointer'}}/></div>:null}


        </div>
 

    </Dialog>
    <div className="D6_Pricing_Block">
 <Grid container  spacing={0} item xs={12} >
 <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose1}>
      <Alert onClose={handleClose1} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>Error in updating try again </strong>
      </Alert>
      </Snackbar>
      <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose1}>
      <Alert onClose={handleClose1} severity="success">
        <AlertTitle>Success</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>Plan updated</strong>
      </Alert>
      </Snackbar>
                <Grid item style={{textAlign:'center'}} xs={12}>
             
             <div class="text1">
                <div class="text-top1">
                    <div>
                    Pricing Plans

                    </div>
                </div>
                <div class="text-bottom1">
                    <div>            Choose the plan that's right for you and your business<br />
</div>
                </div>
             </div>
             </Grid>
             </Grid>




            <div>
               
          <div>
            <Typography>
            </Typography>
          </div>
          <div>
            <Typography>

            </Typography>
          </div>
          </div>
        </div>
        {/* <D6_halo_Pcat  /> */}

        <Container className='cont_pricing' style={{maxWidth:'max-content'}} >
            <Grid container>
            
            <Grid item  sm={3} xs={12}>
                <Paper onClick={()=>Pricing('1')} className={pricing=='1'?'cont_active':'cont_pricing1'} elevation={6}>
<div>
<Typography  className={pricing=='1'?'text_head_active':'text_head'}>
   HALO CARDS
</Typography>
<ul className='ul_text'>
    <li className='li_text'>Personalise your own digital card</li>
    <li className='li_text' >All individual plans free for 40 days
</li>
    <li className='li_text' >Share with Email, Text & QR Code</li>
    <li className='li_text'>Enriched with social media enabled icons
</li>
    
    <li className='li_text'>Save digital card details to your contacts
</li>
    <li className='li_text' >Fully customizable templates</li>
    <li className='li_text'>Additional details for your business</li>
    <li className='li_text' >Add Halo Members as connections</li>
    <li className='li_text' >Go digital, expand your reach
</li>
 
    
</ul>
<div className='div_btn'>
<Button variant="contained"   className={pricing=='1'?'btn_select_active':'btn_select'}>Get started</Button>
</div>
</div>


                </Paper>
            </Grid>
            <Grid item  sm={3} xs={12}>
            <Paper onClick={()=>Pricing('2')} className={pricing=='2'?'cont_active':'cont_pricing1'} elevation={6}>
            <div>
<Typography  className={pricing=='2'?'text_head_active':'text_head'}>
   HALO BUSINEE
</Typography>
<ul className='ul_text'>
    <li className='li_text'>List your products/services/categories</li>
    <li className='li_text' >Share with your network &amp; increase your followers</li>
    <li className='li_text'>Add your contact button (call or whatsapp)
</li>
    <li className='li_text' >Share with Email, Text &amp; QR Code
</li>
    <li className='li_text'>Update anytime instantly 
</li>
    <li className='li_text' >Make your product/service listing live instantly</li>
    {/* <li className='li_text'>Send notifications to your followers with every new listing</li> */}
    <li className='li_text' >Unlimited shares</li>
<li className='li_text' >Go digital, expand your reach
</li>
 
    
</ul>
<div className='div_btn'>
<Button variant="contained"   className={pricing=='2'?'btn_select_active':'btn_select'}>Get started</Button>
</div>
</div>

            </Paper>
            </Grid>
            <Grid item  sm={3} xs={12}>
                <Paper onClick={()=>Pricing('4')} className={pricing=='4'?'cont_active':'cont_pricing1'} elevation={6}>
<div>
<Typography  className={pricing=='4'?'text_head_active':'text_head'}>
   HALO PASS
</Typography>
<ul className='ul_text'>
    <li className='li_text'>Create your own smart pass</li>
    <li className='li_text' >Used as subscriptions, memberships, offers & coupons

</li>
    <li className='li_text' >Fully customisable digital passes</li>
    <li className='li_text'>Generate single or multiple use passes
</li>
    
    <li className='li_text'>Save digital card details to your contacts
</li>
    <li className='li_text' >Manage your events seamlessly</li>
    <li className='li_text'>Edit digital passes on the go</li>
    {/* <li className='li_text' >Share easily with your clients</li> */}
    <li className='li_text' >Avoid printing hassles, save time & environment
</li>
 
    
</ul>
<div className='div_btn'>
<Button variant="contained"   className={pricing=='4'?'btn_select_active':'btn_select'}>Get started</Button>
</div>
</div>


                </Paper>
            </Grid>
            <Grid item  sm={3} xs={12}>
            <Paper onClick={()=>Pricing('3')} className={pricing=='3'?'cont_active':'cont_pricing1'} elevation={6}>
            <div>
<Typography  className={pricing=='3'?'text_head_active':'text_head'}>
    HALO PRO
</Typography>
<ul className='ul_text'>
    <li className='li_text'>Bundled offers incl. cards & business  profiles</li>
    <li className='li_text' >Suitable for multiple businesses</li>
    <li className='li_text'>No need to purchase/recharge  card and profile separately
</li>
    <li className='li_text' >Purchase/Recharge your Halo card & Halo Profiles in one GO
</li>
    <li className='li_text'>Great Value for money
</li>
    <li className='li_text' >Save your time & money</li>
    {/* {/* <li className='li_text'>Additional details for your business</li> */}
    <li className='li_text' >Manage your subscriptions quickly</li>
    <li className='li_text' >Go digital, expand your reach
</li> 
 
    
</ul>
<div className='div_btn'>
<Button variant="contained"   className={pricing=='3'?'btn_select_active':'btn_select'}>Get started</Button>
</div>
</div>
            </Paper>
            </Grid>
            </Grid>
        </Container>


{pricing=='1'?<>
            {/* <Pricingcls cards={cards} scroll1={scroll} country={country} live={live} countryF={countryF} category={category} updatePlan={updatePlan} /> */}
            <Pricingplt cards={cards} country={country} live={live} countryF={countryF} category={category} updatePlan={updatePlan}/>
            </>:pricing=='2'?<Pricing_Profile cards={cards} available={available} scroll1={scroll} country={country} live={live} countryF={countryF} category={category} updatePlan={updatePlan} />
:pricing=='4'?<><Pricing_Pass cards={cards} available={available} scroll1={scroll} country={country}  live={live} countryF={countryF} category={category} updatePlan={updatePlan} downgrade={downgrade} template={template} currentPlan={currentPlan} />
{auth.auth&&auth.auth.passSubscription?
<TopUp cards={cards} scroll1={scroll} country={country} live={live} countryF={countryF} category={category} updatePlan={updatePlan}/>:null}
</>:            <Pricing_Combo cards={cards} scroll1={scroll} country={country} live={live} countryF={countryF} category={category} updatePlan={updatePlan}/>
}
<D6_SL_AF />
            <D6_SL_Footer/>

        </>
    )
     }
}
