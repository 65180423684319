import React from 'react';
import Grid from '@material-ui/core/Grid';
import './D6_halo_subcat.css';
import D6_SLCrouselMain from '../HomePage/Home_SCarousel/D6_SLCrouselMain';

import HIcon from '../../assets/Images/halo-icon.svg';
import { Button, makeStyles, Paper, Typography } from '@material-ui/core';

export default function SubCardSelect(props) {
  // const classes = useStyles();
  const{live}=props
  const category=props.category
  const card1=value=>{
    props.card2(value)
  }

  const useStyles = makeStyles((theme) => ({
  
      'D6_SL_WS_Cont' : {
         marginTop: "20px",
     },
      'D6_SL_WS_Grid1_Div' : {
         textAlign: "center",
         alignItems: "center",
         marginBottom: "20px",
         color: "#36444e",
     },
      'D6_SL_WS_Grid2_DivHI' : {
         marginTop: '0px',
     },
      'D6_SL_WS_Grid1_Div_H1' : {
      fontSize: '48px',
      fontWeight: '300',
      color: '#36444e',
      lineHeight: '48px',
      display: 'block',
      margin:'0 0 30px',
      textAlign: 'center',
      '& span': {
          fontSize: '36px',
      },
     },
     'D6_SL_WS_Grid2_container': {
         width: '100%',
        //  display: 'flex',
        //  margin: '40px 0',
         textAlign: 'center',
         [theme.breakpoints.down('sm')]: {
             flexDirection: 'column',
           },
     },
      'D6_SL_WS_Grid2_Div' : {
         width: "100%",
         margin: "0px auto",
         [theme.breakpoints.down('sm')]: {
             marginBottom: '20px',
           },
     },
     'D6_SL_WS_Grid2_HDiv' : {
         width: "100%",
         borderBottom: "2px solid var(--ycolor)",
         marginBottom: "10px",
         display: 'flex',
     },
     'D6_SL_WS_HIcon': {
        //  display: 'inline-flex',
         width: '40px',
         height: '40px',
     },
     'D6_SL_WS_Grid2_Div_H1' : {
         fontSize: "30px",
         fontWeight: '300',
         color: "#36444e",
         lineHeight: '40px',
         paddingLeft: '20px',
         paddingBottom: '10px',
        //  display: 'inline-flex',
         textAlign: 'left',
     },
     'D6_SL_WS_Grid2_Div_P' : {
         fontSize: "18px",
     },
     }));
  const classes = useStyles();

  return (
    <div className="D6_Card_SubCatContainer_Grid">
      <Grid container > 
            <Grid item sm={6}  style={{padding:'2vh 0px'}} xs={12} className="D6_Card_PreviewSubCatOpts_Grid">
            
                <D6_SLCrouselMain live={live} category={category} card1={card1}/>
             
            </Grid>
             <Grid item   style={{padding:'2vh 0px'}}md={6} xs={12} className={classes['D6_SL_WS_Grid2']} >
                  <Button className='btn_crousel'>
                    <div className={classes['D6_SL_WS_Grid2_Div']}>
                        <div className={classes['D6_SL_WS_Grid2_HDiv']}>
                            <div className={classes['D6_SL_WS_HIcon']}>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className={classes['D6_SL_WS_Grid2_Div_H1']}>Halo Platinum</Typography>
                        </div>
                       <Paper elevation={0} className="D6_Halo_CardsCont">
                        
                          <Grid >
                            <ul>
                            <li className="D6_Halo_CardsDesc">
                                Customizable cover pic, display business hours
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                More social media profiles (incl. 3 pay options)
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Additional notes for more information
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Search through your Halo Cards &amp; Connections
                              </li>
                              <li className="D6_Halo_CardsDesc">
                                Switch &amp; change your platinum card template anytime
                              </li>
                            </ul>
                          </Grid>
                     
                       </Paper>
                    </div>
                    </Button>
                </Grid>
      </Grid>
    </div>
  );
}
