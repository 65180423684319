import React, { useContext, useState ,useEffect} from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  MutedLink,
  SubmitButton,
} from "./D6_FE_CommonElements";
import { sha256 } from 'js-sha256';
import './D6_FE_SignUpForm_CSS.css';
import {SmallText1} from './D6_FE_AccountBox'
import { Marginer } from "./Marginer";
import { AccountContext } from "./accountContext";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


export function D6_FE_SignUpForm(props) {
  const{live}=props
  const { switchToSignin } = useContext(AccountContext);
  const[processing,setProcessing]=useState(false)

  const[email,setEmail]=useState('')
  const[phone,setPhone]=useState('')
  const[fname,setFName]=useState('')
  const[lname,setLName]=useState('')
  const[label,setLabel]=useState(null)

  const[password1,setPassword1]=useState('')
  const[password2,setPassword2]=useState('')
  const[country,setCountry]=useState('')
  var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))).{6,12}$");
  

  useEffect(() => {
    
      fetch('https://ipapi.co/json/',
      {
      method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },
    })
    .then(res => res.json())
    .then(resp=>setCountry(resp.country_name))},[])
  const Password1=(val)=>{
    setLabel(null)
    setPassword1(val)
    if(val.length>6&&!mediumRegex.test(val)){
      setLabel('Password should contain [a-z] [A-Z] [0-9] [6,12]')
    }
  }
  const Password2=(val)=>{
    setLabel(null)
    setPassword2(val)
    if(val.length>6&&!mediumRegex.test(val)){
      setLabel('Password should contain [a-z] [A-Z] [0-9] [6,12]')
    }
  }  
   const sigupClicked=()=>{
    if(email&&phone&&fname&&lname&&country) {
  
      setProcessing(true)


     fetch(live+'member',
          // fetch(' http://127.0.0.1:8000/member',

      {
      method: 'POST',
      headers:{
        'Content-Type' :'application/json'  
      },
      body: JSON.stringify({fname:fname,lname:lname,email:email,phone:phone,country:country})
    })
    .then(res => res.json())
    .then((resp)=>otpClicked(resp))

     
   }
  else
  alert('All Fields are necessary')

}
   const otpClicked=data=>{
    setProcessing(null)

    if(data.data){
      props.otpClicked(data)
    }
  else{
    setLabel(data.detail)
    setProcessing(null)
  }


   
 }



  return (
    <BoxContainer>
                <div className="SignIn_Form_Div">

                  <ValidatorForm  onSubmit={sigupClicked} >
        {/* <Input type="text" onChange={(e)=>setFName(e.target.value)} placeholder="First Name" /> */}
        <div className="D6_SIForm_TV_Div">

        <TextValidator
                      label=""
                      onChange={(e)=>setFName(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      value={fname}     
                      placeholder='First Name'
                  />
</div>        {/* <Input type="text" onChange={(e)=>setLName(e.target.value)} placeholder="Last Name" /> */}
        
<div className="D6_SIForm_TV_Div">
<TextValidator
                      label=""
                      onChange={(e)=>setLName(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      value={lname}     
                      placeholder='Last Name'
                      errorMessages={['last name is required']}
                  />
                  </div>
                  <div className="D6_SIForm_TV_Div">

                     <TextValidator
                      label=""
                      onChange={(e)=>setPhone(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='number'
                      value={phone}     
                      placeholder='Phone'
                      validators={['required']}
                      errorMessages={['Phone is required']}
                  />
                  </div>
                  <div className="D6_SIForm_TV_Div">

                  <TextValidator
                      label=""
                      onChange={(e)=>setEmail(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='email'
                      value={email}     
placeholder='Email'
                      validators={['required','isEmail']}
                      errorMessages={['Email is required']}
                  /></div>
                          {/* <div className="D6_SIForm_TV_Div">

                        <TextValidator
                      label=""
                      onChange={(e)=>Password1(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='password'
                      placeholder='Password'
                      value={password1}     
                      errorMessages={['Mobile is required']}
                  /></div>
                          <div className="D6_SIForm_TV_Div">

                        <TextValidator
                      label=""
                      onChange={(e)=>Password2(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='password'

                      placeholder= 'Confirm Password'

                      value={password2}     
                      errorMessages={['Email/Mobile is required']}
                  />
                  </div> */}
        {/* <Input type="number"  onChange={(e)=>setPhone(e.target.value)}placeholder="Mobile Number" />
        <Input type="email"  onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
        <Input type="password" onChange={(e)=>setPassword1(e.target.value)} placeholder="Password" />
        <Input type="password" onChange={(e)=>setPassword2(e.target.value)}placeholder="Confirm Password" /> */}

        {/* <Input type="text" onChange={(e)=>setCountry(e.target.value)} placeholder="Country" /> */}
        {/* <div > */}
        {/* <FormContainer>
        <CountryDropdown
          value={country}
          onChange={(val) =>setCountry(val)} />
          </FormContainer> */}
        {(!label?null:<label className="D6_SIForm_errorpass">{label}</label>)}

      <Marginer direction="vertical" margin={10} />
      <SubmitButton disabled={processing} type="submit" >{!processing?'Sign Up':'Processing'}</SubmitButton>

      <Marginer direction="vertical" margin="1em" />
      <MutedLink href="#">
        Already have an account?{" "} 
        <BoldLink href="#" onClick={switchToSignin}> 
           Sign in
        </BoldLink>
      </MutedLink>
      <SmallText1>By signing up, you agree to our Terms and Privacy Policy.</SmallText1>
      </ValidatorForm>
</div>
    </BoxContainer>
  );
}
