import React, {useEffect,useContext ,useState} from 'react'
import   {BoldLink,BoxContainer,MutedLink,SubmitButton} from './D6_FE_CommonElements';
import { Marginer } from "./Marginer";
import {SmallText1} from './D6_FE_AccountBox'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import './D6_FE_LoginForm_CSS.css'
import { sha256 } from 'js-sha256';
import {useCookies} from 'react-cookie'

import { AccountContext } from "./accountContext";



function D6_FE_LoginForm(props)  {
  const{live}=props
      const[auth,setAuth]=useCookies(['auth'])
  
  const { switchToSignup } = useContext(AccountContext);
  const[processing,setProcessing]=useState(null)

   
      const [email,setEmail]=useState('')
      const[password,setPassword]=useState('')
      const[label,setLabel]=useState(null)
      var hash=sha256(password)

   const Password=(val)=>{
     setLabel(null)
     setPassword(val)
   
    
   }
 

    const handleSubmit = () => {
      setProcessing('true')
     

        fetch(live+'member',
        //  fetch(' http://127.0.0.1:8000/member',

        {
        method: 'PUT',
        headers:{
          'Content-Type' :'application/json'  
        },
        body: JSON.stringify({username:email})
      })
      .then(res => res.json())
      .then((resp)=>otpClicked(resp))  
    
    }
    
    const forgotClicked = () => {
  //  fetch(' http://127.0.0.1:8000/forpass',
   fetch(live+'forpass',
   {
   method: 'POST',

   headers:{
     'Content-Type' :'application/json'  
   },
   body: JSON.stringify({email:email})
 })
 .then(res => res.json())
 .then((resp)=>otpClicked(resp))  


     

      

  
  }
    const otpClicked=data=>{
      setProcessing(null)

      if(data.data&&data.data.email==='test25@halodigital.app'){
        props.handleClose()



        setAuth('auth',data.data,{
          maxAge: 3600 ,
          expires:0,
          path:'/'
        })
      }
if(data.data&&data.data.email!=='test25@halodigital.app'){


  props.otpClicked(data)
}

    else{
      setLabel(data.detail)
    }
  

     
   }

    
   
    return (
      <BoxContainer>
       
          <div className="SignIn_Form_Div">
            <ValidatorForm
              //  useRef="form"
                onSubmit={handleSubmit}
               
            >

            <div className="D6_SIForm_TV_Div">
              <label className="D6_SIForm_Label">Email<span className="mandaotry">*</span></label>
              <TextValidator
                      label=""
                      onChange={(e)=>setEmail(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      value={email}     
                      validators={['required']}
                      errorMessages={['Email is required']}
                  />
            </div>

            {/* <div className="D6_SIForm_TV_Div">
                <label className="D6_SIForm_Label">Password<span className="mandaotry">*</span></label>
               <TextValidator
                    label=""
                    value={password}     
                    className="D6_SIForm_errorf"
                    onChange={(e)=>Password(e.target.value)}
                    name="password"
                    type="password"
                    validators={['required']}
                    errorMessages={['Password is required']}
                   
                />
            </div> */}
            {/* {(!label||!email?null:label=='Invalid Password'||label=='User Doesnot Exsist'?<label className="D6_SIForm_error">Invalid Credentials</label>:null)} */}
            {(!label?null:<label className="D6_SIForm_error">{label}</label>)}

           
          
          <Marginer direction="vertical" margin="0.8em" />
          <SubmitButton disabled={processing} type="submit">{!processing?'Sign in':'Processing'}</SubmitButton>
          <Marginer direction="vertical" margin="0.5em" />
          {/* <MutedLink onClick={forgotClicked} className="D6_OTPPage_link">Reset your password?</MutedLink> */}
          <MutedLink onClick={switchToSignup} className="D6_OTPPage_link"> Don't have an account? SignUp</MutedLink>
          {/* <SmallText1>On SignUp, you agree to accept our terms and privacy policy.</SmallText1> */}
        

          </ValidatorForm>
        </div>
      </BoxContainer>
    );
    }
    export default D6_FE_LoginForm;

  